.loading {
    @include adjust-flex();
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.p-home {
    height: 100vh;
    overflow: hidden;
    position: relative;
    background-color: $forest-green;

    &_logo {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        padding: 0 rem(16);
        margin-top: rem(24);

        &-image {
            width: 100%;
            max-width: rem(277);
            margin: 0 auto;
        }

    }

    img {
        width: 100%;
        height: auto;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    &_text {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: rem(16);
        padding: rem(4);
        background: linear-gradient(222.93deg, $naples-yellow03 -3.82%, $jasmine0 103.24%);
        backdrop-filter: blur(5.5px);
        border-radius: rem(20);
        width: calc(100% - 32px);
    }

    &_warningText {
        margin-bottom: rem(4);
        margin-top: rem(4);
    }

    &_invalid {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0 rem(16);
        @include adjust-flex();
    }
}
