html,
body {
    font-family: $font-inter;
    font-size: 16px;
    overflow-x: hidden;
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent; //issue click in mobile flash background highligh
}

button {
    &:focus {
        outline: none;
    }
}

p {
    margin-bottom: 0;
    margin-top: 0;
}

a {
    text-decoration: none;
}

[role='button'],
button {
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

input {
    word-break: normal;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

.hidden-animation {
    visibility: hidden;
}

.overflow-hidden {
    height: 100vh;
}

.u-mt {
    &-24 {
        margin-top: rem(24);
    }

    &-16 {
        margin-top: rem(16);
    }
}

.wrapper {
    transform-origin: 50% 50%;
    perspective: 100px;

    svg {
        display: inline-block;
        width: 350px;
        padding: 0 100px;
        margin-top: -24px;
    }
}

.hand-tap {
    animation: tap 1.25s ease-out backwards;
    animation-iteration-count: infinite;
    fill: #fff;
    stroke: #000;
    stroke-width: 3px;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.webView {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
}

.button-location {
    background-color: #007125;
    padding: 16px;
    border: 1px solid #fff;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
}

.notfound {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include adjust-flex();
}
