// Text Colors
$white: rgb(255, 255, 255); // #ffffff
$black: rgb(0, 0, 0); // #000000
$la-salle-green: rgb(0, 113, 37); //#007125
$eerie-black: rgb(22, 24, 25); //#161819
$green-pantone: rgb(0, 162, 53); // #00a235
$grayx11: rgb(188, 191, 193); // #bcbfc1
$gainsboro: rgb(222, 223, 224); //#dedfe0
$red-orange: rgb(255, 77, 79); //#ff4d4f
$gainsboro2: rgb(217, 217, 217); // #d9d9d9
$white-smoke: rgb(245, 245, 245); // #f5f5f5
$smoky-black: rgb(9, 10, 10); //#090a0a
$naples-yellow: rgb(255, 220, 94); //#ffdc5e
$jasmine: rgb(255, 224, 116); //#ffdc5e
$forest-green: rgb(23, 128, 36); //	#178024

// Overlays
$white-overlay04: rgba($white, 0.4);
$black-012: rgba($black, 0.12);
$black05: rgba($black, 0.5);
$naples-yellow03: rgba($naples-yellow, 0.3);
$jasmine0: rgba($jasmine, 0);

// Linears
$primary-linear: linear-gradient(88.54deg, $la-salle-green -1.55%, $green-pantone 100.28%);
