@keyframes zoomIn {
    from {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }

    50% {
        opacity: 1;
    }
}

@keyframes move {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes fadeInBlur {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@keyframes animationFramesLeft {
    0% {
        transform: translate(20%, 0) rotate(0deg) scale(0.8);
    }

    50% {
        transform: translate(-50%, 0) rotate(8deg) scale(1.4);
    }

    100% {
        transform: translate(0, 0) rotate(0deg) scale(1);
    }
}

@keyframes animationFramesRight {
    0% {
        transform: translate(-20%, 0) rotate(0deg) scale(0.8);
    }

    50% {
        transform: translate(50%, 0) rotate(8deg) scale(1.4);
    }

    100% {
        transform: translate(0, 0) rotate(0deg) scale(1);
    }
}


@keyframes beatSmall {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1.15);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInLeft {
    from {
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInRight {
    from {
        transform: translate3d(100%, 0, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes scaleX {
    from {
        opacity: 0;
        transform: scaleX(0);
    }

    to {
        opacity: 1;
        transform: scaleX(1);
    }
}

@keyframes scaleY {
    from {
        opacity: 0;
        transform: scaleY(0);
    }

    to {
        opacity: 1;
        transform: scaleY(1);
    }
}

@keyframes tap {
    0% {
        transform: rotateX(0deg);
    }

    10% {
        transform: rotateX(12.5deg);
    }

    25% {
        transform: rotateX(25deg);
    }
}

:root {
    --animate-duration: 1s;
    --animate-delay: 1s;
    --animate-repeat: 1;
}

.preanimate {
    opacity: 0;
}

.animate {
    animation-duration: var(--animate-duration);
    animation-fill-mode: both;

    &-fadeInUp {
        animation-duration: 1s;
        animation-name: fadeInUp;
        opacity: 0;
    }

    &-slideInLeft {
        animation-name: slideInLeft;
    }

    &-slideInRight {
        animation-name: slideInRight;
    }

    &-zoomIn {
        animation-name: zoomIn;
    }
  
    &-move {
        animation-name: move;
    }

    &-fadeInBlur {
        animation-name: fadeInBlur;
    }

    &-animationFramesLeft {
        animation-name: animationFramesLeft;
    }

    &-animationFramesRight {
        animation-name: animationFramesRight;
    }

    &-beatSmall {
        animation-name: beatSmall;
    }

    &-scaleX {
        animation-name: scaleX;
    }

    &-scaleY {
        animation-name: scaleY;
    }
}
